<script>
/**
 * Planifications Data component
 */
import EditPlanification from "./update-planification";
import { authComputed, planificationsMethods } from "@/state/helpers";
export default {
  props: ["planifications", "busy", "loaderStatus"],
  components: {
    EditPlanification,
  },
  data() {
    return {
      planification: null,
      planificationsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != 'USER' && this.loggedIn.user.role != 'OPERATOR') {
        return [
          { key: "centre", sortable: false, label: this.$t("dataTable.centre.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
          { key: "date", sortable: false, label: this.$t("dataTable.date.text"), class: "tdClass" },
          { key: "actions", sortable: false, label: this.$t("dataTable.tools.text"), class: "tdClass" },
        ];
      } else {
        return [
          { key: "centre", sortable: false, label: this.$t("dataTable.centre.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
          { key: "date", sortable: false, label: this.$t("dataTable.date.text"), class: "tdClass" },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...planificationsMethods,
    showModalEdit() {
      this.$bvModal.show("edit-planification-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-planification-modal");
    },
    edit(planification) {
      this.updatePlanification(planification)
        .then(() => {
          this.makeToast(
            "Update planification",
            "planification has been updated",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Update planification", error, "danger");
        });
    },
    passPlanification(type, planification) {
      if (type == "edit") {
        this.planification = planification;
        this.showModalEdit();
      } else if (type == "delete") {
        this.$swal
          .fire({
            title: this.$t('modals.askValidDelete.text'),
            text: this.$t('modals.cantRevert.text'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delPlanification(planification.id)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The planification has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      //this.$router.push({name:'alert', params: { serial: record.serial }})
      console.log(record);
    },
  },
  watch: {
    planifications(newVal) {
      this.planificationsList = []
      newVal.forEach(obj=>{
        let level = 3
        const dateNow = new Date()
        const datePlan = new Date(obj.date)
        const dateNowParsed = {
          year: dateNow.getFullYear(),
          month: dateNow.getMonth(),
          day: dateNow.getDate()
        }
        const datePlanParsed = {
          year: datePlan.getFullYear(),
          month: datePlan.getMonth(),
          day: datePlan.getDate()
        }
        const year = datePlanParsed.year - dateNowParsed.year
        const month = datePlanParsed.month - dateNowParsed.month
        const day = datePlanParsed.day - dateNowParsed.day

        if(year == 0 && month == 0 && day < 0) level = 3
        if(year == 0 && month == 0 && day in [0, 1]) level = 2
        if(year == 0 && month == 0 && day > 1) level = 1
        this.planificationsList.push({
          ...obj,
          centre: obj.centre.name,
          province: obj.province.name,
          level,
          date: `${new Date(obj.date).getDate()} - ${new Date(obj.date).getMonth() + 1} - ${new Date(obj.date).getFullYear()}`
        })
      })
    },
  },
};
</script>

<template>
  <div>
    <div
      class="table-responsive mb-3 bg-white rounded-lg"
      style="min-height: 350px"
    >
      <b-table
        class="mb-0"
        hover
        show-empty
        centred
        :busy="busy"
        :empty-text="$t('dataTable.noPlanifications.text')"
        :items="planificationsList"
        :fields="fieldsComputed"
        v-bind:style="{ cursor: `pointer` }"
        stacked="sm"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <strong>{{$t('dataTable.loading.text')}}</strong>
          </div>
        </template>
        <template v-slot:cell(date)="row">
          <div 
            :style='{
              "color": row.item.level == 3 ? "red" : row.item.level == 2 ? "orange" : "green"
            }'
          >
            <strong>{{row.item.date}}</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="d-inline-flex align-items-center">
            <b-button
              type="button"
              class="mr-2"
              variant="light"
              @click="passPlanification('edit', data.item)"
              >{{$t("buttons.edit.text")}}
            </b-button>
            <b-button
              type="button"
              variant="danger"
              @click="passPlanification('delete', data.item)"
              >{{$t("buttons.delete.text")}}
            </b-button>
          </div>
        </template>
      </b-table>
      <EditPlanification v-bind:planification="planification" @editPlanification="edit" />
    </div>
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>