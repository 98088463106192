<script>
/**
 * Actions Data component
 */
import moment from "moment";
import { authComputed, interventionsMethods } from "@/state/helpers";
export default {
  props: ["interventionsData", "busy"],
  data() {
    return {
      interventionsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          {
            key: "date",
            sortable: false,
            label: "Date",
            class: "tdClass",
          },
          {
            key: "serial",
            sortable: false,
            label: "Serial",
            class: "tdClass",
          },
          {
            key: "codeQr",
            sortable: false,
            label: "code Qr",
            class: "tdClass",
          },
          {
            key: "equipement",
            sortable: false,
            label: "Equipement",
            class: "tdClass",
          },
          {
            key: "marque",
            sortable: false,
            label: "Marque",
            class: "tdClass",
          },
          {
            key: "operations",
            sortable: false,
            label: "Opérations",
            class: "tdClass",
          },
          {
            key: "actions",
            sortable: false,
            label: this.$t("dataTable.tools.text"),
            class: "tdClass",
          },
        ];
      } else {
        return [
          {
            key: "date",
            sortable: false,
            label: "Date",
            class: "tdClass",
          },
          {
            key: "serial",
            sortable: false,
            label: "Serial",
            class: "tdClass",
          },
          {
            key: "codeQr",
            sortable: false,
            label: "code Qr",
            class: "tdClass",
          },
          {
            key: "equipement",
            sortable: false,
            label: "Equipement",
            class: "tdClass",
          },
          {
            key: "marque",
            sortable: false,
            label: "Marque",
            class: "tdClass",
          },
          {
            key: "operations",
            sortable: false,
            label: "Opérations",
            class: "tdClass",
          }
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...interventionsMethods,
    passEvent(type, intervention) {
      if (type == "delete") {
        this.$swal
          .fire({
            title: this.$t("modals.askValidDelete.text"),
            text: this.$t("modals.cantRevert.text"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delIntervention(intervention.id)
                .then((response) => {
                  this.$emit("updatePage");
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The intervention has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    interventionsData(newVal) {
      let interv = [];
      newVal.forEach((intervention) => {
        let operationData = [];
        intervention.operations.forEach((operation) => {
          operationData.push(
            `${operation.operation.name} - ${operation.action.name}`
          );
        });
        let equipments = {
          1: "REFRIGERATEUR",
          2: "CONGELATEUR",
          3: "ARMOIRES FRIGORIFIQUE",
          4: "CHAMBRE FROIDE",
          5: "CAMION",
          6: "SUPER CONGELATEUR",
        };
        let dataTranformed = {
          date: moment(intervention.createdAt).format("DD-MM-YYYY HH:mm:ss"),
          type: intervention.type,
          codeQr: intervention.device ? intervention.device.ref : "",
          marque: intervention.device ? intervention.device.marque : "",
          serial: intervention.device ? intervention.device.serial : "",
          equipement: intervention.device
            ? equipments[intervention.device.type]
            : "",
          operations: operationData.join("<br />"),
        };
        interv.push(dataTranformed);
      });
      this.interventionsList = interv;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="table-responsive mb-3 bg-white rounded-lg"
      style="min-height: 150px"
    >
      <b-table
        class="mb-0"
        hover
        show-empty
        centred
        :busy="busy"
        striped
        borderless
        :empty-text="$t('dataTable.noInterventions.text')"
        :items="interventionsList"
        :fields="fieldsComputed"
        v-bind:style="{ cursor: `pointer` }"
        stacked="sm"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <strong>{{ $t("dataTable.loading.text") }}</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="d-inline-flex align-items-center">
            <b-button
              type="button"
              variant="danger"
              @click="passEvent('delete', interventionsData[data.index])"
              >{{ $t("buttons.delete.text") }}
            </b-button>
          </div>
        </template>
        <template v-slot:cell(operations)="data">
          <span v-html="data.value"></span>
        </template>
      </b-table>
    </div>
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>