<script>
import L from "leaflet";
import {
  LMap,
  LMarker,
  LPolyline,
  LTileLayer,
  LTooltip,
  LIcon,
} from "vue2-leaflet";
/**
 * Position component
 */

export default {
  props: ["device", "events", "loader"],
  created() {
    this.setDevice(this.device);
    this.setEvents(this.events);
    //this.chargePosition(this.room);
  },
  components: {
    LMap,
    LPolyline,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
  },
  data() {
    return {
      deviceData: {},
      eventsData: [],
      positionsData: [],
      mapCenter: [30.105314, -7.684968],
      mapZoom: 4,
      marker: {
        position: undefined,
      },
      markerStart: {
        position: undefined,
      },
      //url: "https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=jab0Ntex6UOajMfdGw1Z9P4sLbfqsfBGQ6UQ6_4NKps",
      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      //url: "http://tile.openstreetmap.ma/osm/{z}/{x}/{y}.png",
      staticAnchor: [16, 10],
      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      iconAnchorStart: [8, 16],
      iconSizeStart: [20, 20],

      imageStart: require("@/assets/images/PositionCamionStart.svg"),

      imageDeviceGreen: require("@/assets/images/pin_green_camion.svg"),
      imageDeviceGrey: require("@/assets/images/pin_grey_camion.svg"),
      imageDeviceBlue: require("@/assets/images/pin_blue_camion.svg"),

      updateStatus: false,
      newPosition: {
        lat: null,
        lng: null,
      },
    };
  },
  methods: {
    setDevice(device) {
      this.deviceData = device;
    },
    setEvents(events) {
      let length = events.length;
      if (length > 0) {
        let positions = events.filter((obj) => obj.lat != 0 && obj.lng != 0);
        this.marker = {
          ...this.device,
          position: { lat: positions[0].lat, lng: positions[0].lng },
        };
        this.markerStart = {
          position: {
            lat: positions[length - 1].lat,
            lng: positions[length - 1].lng,
          },
        };
        this.positionsData = [];
        positions.reverse();
        positions.map((obj) => {
          this.positionsData.push([obj.lat, obj.lng]);
        });
      }
    },
  },
  watch: {},
};
</script>

<template>
  <div style="height: 270px">
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="loader == false" class="card-body" style="position: relative">
      <l-map
        style="height: 260px; width: 100%"
        :zoom="marker.position ? 7 : mapZoom"
        :min-zoom="4"
        :center="marker.position ? marker.position : mapCenter"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        <l-marker
          :zIndexOffset="100"
          v-if="marker.position"
          :lat-lng="marker.position"
          :icon="icon"
        >
          <l-icon
            :icon-anchor="iconAnchor"
            :icon-size="iconSize"
            :tooltipAnchor="tooltipAnchor"
          >
            <img :v-if="marker.province.name == 'Camion'" :src="imageDeviceGreen"/>
            <img else :src="imageDeviceBlue"/>
          </l-icon>
          <l-tooltip
            class="rounded"
            :options="{
              interactive: 'true',
              direction: 'top',
            }"
          >
            <span style="font-weight: 550"
              >{{ $t("tooltips.name.text") }} :
            </span>
            {{ marker.name }}<br />
            <span style="font-weight: 550"
              >{{ $t("tooltips.stockType.text") }} :
            </span>
            <span
              class="badge font-size-12"
              :class="{
                'badge-danger':
                  `${marker.stockType}` === '24H' ||
                  `${marker.stockType}` === '24 H' ||
                  `${marker.stockType}` === '24 H ' ||
                  `${marker.stockType}` === '24H ',
                'badge-warning':
                  `${marker.stockType}` === 'DEMI JOURNEE' ||
                  `${marker.stockType}` === 'jusqu\'à 20H' ||
                  `${marker.stockType}` === 'Jusqu\'à 20h',
                'badge-success':
                  `${marker.stockType}` === 'VIDE' ||
                  `${marker.stockType}` === 'VIDE',
                'badge-secondary':
                  `${marker.stockType}` === '-' ||
                  `${marker.stockType}` === 'NON COMMUNIQUE',
              }"
            >
              {{ marker.stockType }} </span
            ><br />
            <span style="font-weight: 550">Province : </span>
            {{ marker.province.name }}
          </l-tooltip>
        </l-marker>
        <l-marker
          :zIndexOffset="100"
          v-if="markerStart.position"
          :draggable="false"
          :lat-lng="markerStart.position"
          :icon="icon"
        >
          <l-icon
            :icon-anchor="iconAnchor"
            :icon-size="iconSize"
            :tooltipAnchor="tooltipAnchor"
          >
            <img :src="imageDeviceGrey"/>
          </l-icon>
        </l-marker>
        <l-polyline :lat-lngs="positionsData" color="#FF2424"></l-polyline>
      </l-map>
    </div>
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
}
</style>