<script>
import Layout from "../../layouts/main";

import HorizontalMenu from "../../../components/horizontal-navbar-2.vue";
import DevicesMap from "./transport-devices-map";

import {
  layoutComputed,
  authComputed,
  layoutMethods,
  devicesMethods,
  devicesComputed,
} from "@/state/helpers";
/**
 * Transport component
 */
export default {
  page: {
    title: "Transport",
    meta: [{ name: "Transport" }],
  },
  components: {
    Layout,
    HorizontalMenu,
    DevicesMap,
  },
  mounted() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "hidden";
    this.changeMenuType();
  },
  created() {
    this.retrieveDevices();
    /* this.intervalCamions = setInterval(()=>{
      this.retrieveDevices();
    }, 10000); */
  },
  data() {
    return {
      title: "Transport",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Transport",
          active: true,
        },
      ],
      intervalCamions: null,
      loaderStatus: false,

      devicesData: [],
      route: "/transport",
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,
    userRole() {
      return this.loggedIn.user.role;
    },
  },
  methods: {
    ...layoutMethods,
    ...devicesMethods,
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
    retrieveDevices() {
      this.loaderStatus = true;
      this.getDevicesTransportBy({ province: "Camion,Ulc" }).then(
        () => {
          this.loaderStatus = false;
        }
      );
    },
  },
  watch: {
    getDevicesTransport(newVal) {
      let devicesFiltred = [];
      let results = newVal.results;
      /* let keys = Object.keys(results);
      keys.map(obj=>{
        let lat = results[obj].event ? results[obj].event.lat : null;
        let lng = results[obj].event ? results[obj].event.lng : null;
        if (lat && lng) {
            devicesFiltred.push({
              ...results[obj],
              lat: lat,
              lng: lng,
            });
          }
      }) */
      results.map((obj) => {
        if (obj.event) {
          let lat = obj.event.lat > 0 ? obj.event.lat : obj.lat;
          let lng = obj.event.lng < 0 ? obj.event.lng : obj.lng;
          if (lat > 0 && lng < 0) {
            devicesFiltred.push({
              ...obj,
              lat: lat,
              lng: lng,
            });
          }
        }
      });
      this.devicesData = devicesFiltred;
    },
    getProvinces(newVal) {
      this.provincesData = newVal.results;
    },
  },
  beforeDestroy() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "visible";
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <Horizontal-menu class="horiMenu" v-bind:page="route" />
    <DevicesMap v-bind:devices="devicesData" :loader="loaderStatus" />
  </Layout>
</template>
<style scoped>
@media (min-width: 761px) {
  div /deep/ .footer {
    display: none;
  }
  div /deep/ #page-topbar {
    display: none;
  }
  div /deep/ .vertical-menu {
    display: none;
  }
}

@media (max-width: 760px) {
  .horiMenu{
    display: none;
  }
}

div /deep/ .container-fluid {
  padding: 0px;
}
div /deep/ .page-content {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
div /deep/ .main-content {
  margin-left: 0px;
}
</style>