import { planificationsService } from '../../helpers/backend/planifications.service';

export const state = {
    planifications: {},
    upload: null
}

export const getters = {
    getPlanifications(state) {
        return state.planifications
    },
}

export const actions = {

    addPlanification({ commit }, params) {
        return planificationsService.addPlanification(params)
            .then(
                planification => {
                    commit("ADD_PLANIFICATION_SUCCESS", planification)
                    return Promise.resolve(planification);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updatePlanification({ commit }, params) {
        return planificationsService.updatePlanification(params)
            .then(
                planification => {
                    commit("UPDATE_PLANIFICATION_SUCCESS", planification)
                    return Promise.resolve(planification);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delPlanification({ commit }, id) {
        return planificationsService.delPlanification(id)
            .then(
                planification => {
                    commit("DEL_PLANIFICATION_SUCCESS", id)
                    return Promise.resolve(planification);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getPlanificationsBy({ commit }, params) {
        return planificationsService.getPlanifications(params)
            .then(
                planifications => {
                    commit("GET_PLANIFICATIONS_SUCCESS", planifications);
                    return Promise.resolve(planifications)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    uploadPlanifications({ commit }, params) {
        return planificationsService.uploadPlanifications(params)
            .then(
                result => {
                    commit("UPLOAD_PLANIFICATION_SUCCESS", result);
                    return Promise.resolve(result);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearPlanifications({ commit }) {
        commit("CLEAR_PLANIFICATIONS")
    }
};

export const mutations = {
    GET_PLANIFICATIONS_SUCCESS(state, planifications) {
        state.planifications = planifications;
    },
    UPLOAD_PLANIFICATION_SUCCESS(state, result) {
        state.upload = result;
    },
    ADD_PLANIFICATION_SUCCESS(state, planification) {
        let results = [planification, ...state.planifications.results]
        state.planifications = {...state.planifications, results: results}
    },
    UPDATE_PLANIFICATION_SUCCESS(state, planification) {
        let results = state.planifications.results.map(obj => obj.id == planification.id ? planification : obj)
        state.planifications = {...state.planifications, results: results}
    },
    DEL_PLANIFICATION_SUCCESS(state, id) {
        let results = state.planifications.results.filter(obj => obj.id != id)
        state.planifications = {...state.planifications, results: results}
    },
    CLEAR_PLANIFICATIONS(state) {
        state.planifications = {}
    },
};