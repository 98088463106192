<template>
  <div>
    <b-modal
      id="edit-device-modal"
      ref="edit-device-modal"
      size="lg"
      @hidden="cancel"
      :title="$t('modals.updateDevice.text')"
    >
      <div class="row">
        <!-- Serial -->
        <div class="col-6 col-lg-6">
          <label for="serial">Serial</label>
          <b-form-input
            id="serial"
            v-model="deviceEdit.serial"
            class="mb-1"
            placeholder="Serial"
            :state="err.serial ? false : null"
            disabled
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.serial }}
          </b-form-invalid-feedback>
        </div>
        <!-- Reference -->
        <div class="col-6 col-lg-6">
          <label for="reference">{{ $t("modals.reference.text") }}</label>
          <b-form-input
            id="reference"
            v-model="deviceEdit.ref"
            class="mb-1"
            :placeholder="$t('modals.reference.text')"
            :state="err.ref ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.ref }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Name -->
        <div class="col-6 col-lg-6">
          <label for="name">{{ $t("modals.name.text") }}</label>
          <b-form-input
            id="name"
            v-model="deviceEdit.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
        <!-- Type -->
        <div class="col-6 col-lg-6">
          <label for="type">{{ $t("modals.type.text") }}</label>
          <b-form-select
            id="type"
            :options="deviceTypesData"
            v-model="deviceEdit.type"
            class="mb-1"
            :state="err.type ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.type }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Marque -->
        <div class="col-6 col-lg-6">
          <label for="marque">Marque</label>
          <b-form-select
            id="marque"
            :options="marquesData"
            v-model="deviceEdit.marque"
            class="mb-1"
            :state="err.marque ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.marque }}
          </b-form-invalid-feedback>
        </div>
        <!-- Thermostat -->
        <div class="col-6 col-lg-6">
          <label for="thermostat">Thermostat</label>
          <b-form-select
            id="thermostat"
            :options="thermostatData"
            v-model="deviceEdit.thermostat"
            class="mb-1"
            :state="err.thermostat ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.thermostat }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Region -->
        <div class="col-6 col-lg-6">
          <label for="region">{{ $t("modals.region.text") }}</label>
          <b-form-select
            id="region"
            v-model="region"
            :options="regionsData"
            class="mb-1"
            placeholder="Region"
            :state="err.region ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.region }}
          </b-form-invalid-feedback>
        </div>
        <!-- Province -->
        <div class="col-6 col-lg-6">
          <label for="province">{{ $t("modals.province.text") }}</label>
          <b-form-select
            id="province"
            v-model="deviceEdit.province"
            :options="provincesData"
            class="mb-1"
            placeholder="Province"
            :state="err.province ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.province }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="row">
        <!-- Stock Type -->
        <div class="col-12 col-lg-12">
          <label for="stockType">{{ $t("modals.stockType.text") }}</label>
          <b-form-select
            id="stockType"
            v-model="deviceEdit.stockType"
            :options="stockTypesData"
            class="mb-1"
            :state="err.stockType ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.stockType }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Vaccintypes -->
      <div class="row">
        <div class="col-12 col-lg-12" data-app>
          <label for="vaccintypes">{{ $t("modals.vaccinType.text") }}</label>
          <v-select
            id="vaccintypes"
            v-model="vaccintypesSelect"
            :items="vaccintypesFilterData"
            :menu-props="{ maxHeight: '200' }"
            item-text="text"
            item-value="value"
            multiple
          ></v-select>
        </div>
      </div>
      <!-- Phones -->
      <div class="row">
        <div class="col-12 col-lg-12" data-app>
          <label for="phones">{{ $t("modals.contacts.text") }}</label>
          <b-form-tags
            input-id="phones"
            v-model="deviceEdit.phones"
            :placeholder="$t('modals.addContact.text')"
            :add-button-text="$t('buttons.add.text')"
            remove-on-delete
          ></b-form-tags>
        </div>
      </div>
      <div class="row">
        <!-- Lat -->
        <div class="col-6 col-lg-6">
          <label for="lat">{{ $t("modals.lat.text") }}</label>
          <b-form-input
            id="lat"
            v-model="deviceEdit.lat"
            class="mb-1"
            placeholder="Lat"
            :state="err.lat ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.lat }}
          </b-form-invalid-feedback>
        </div>
        <!-- Lng -->
        <div class="col-6 col-lg-6">
          <label for="lng">{{ $t("modals.lng.text") }}</label>
          <b-form-input
            id="lng"
            v-model="deviceEdit.lng"
            class="mb-1"
            placeholder="Lng"
            :state="err.lng ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.lng }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{ $t("buttons.update.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["device", "regions", "provinces", "vaccintypes", "marques"],
  created() {
    //this.chargeVaccintypes();
  },
  data() {
    return {
      regionsData: [
        { value: null, text: this.$t("titles.regions.text"), disabled: true },
      ],
      provincesData: [
        { value: null, text: this.$t("titles.provinces.text"), disabled: true },
      ],
      deviceTypesData: [
        { value: null, text: this.$t("titles.deviceTypes.text"), disabled: true },
        { value: 1, text: "REFRIGERATEUR" },
        { value: 2, text: "CONGELATEUR" },
        { value: 3, text: "ARMOIRES FRIGORIFIQUE" },
        { value: 4, text: "CHAMBRE FROIDE" },
        { value: 5, text: "CAMION" },
      ],
      marquesData: [{ value: null, text: this.$t("titles.marques.text"), disabled: true }],/* [
        { value: null, text: "Marques", disabled: true },
        { value: "SIERA", text: "SIERA" },
        { value: "OJ COMPANY", text: "OJ COMPANY" },
        { value: "LIEBHERR", text: "LIEBHERR" },
        { value: "SIAFMO", text: "SIAFMO" },
        { value: "BEKO", text: "BEKO" },
        { value: "ARISTON", text: "ARISTON" },
        { value: "HOTPOINT", text: "HOTPOINT" },
        { value: "MASTERKIT", text: "MASTERKIT" },
        { value: "BRANDT", text: "BRANDT" },
        { value: "FITCO", text: "FITCO" },
        { value: "LG", text: "LG" },
        { value: "FAGOR", text: "FAGOR" },
        { value: "WHIRLPOOL", text: "WHIRLPOOL" },
        { value: "SAMSUNG", text: "SAMSUNG" },
        { value: "BOSCH", text: "BOSCH" },
        { value: "PASSAP", text: "PASSAP" },
        { value: "BIOBASE", text: "BIOBASE" },
        { value: "DAIKO", text: "DAIKO" },
        { value: "HAIER", text: "HAIER" },
        { value: "POLERNO", text: "POLERNO" },
        { value: "CORECO", text: "CORECO" },
        { value: "NC", text: "NC" }
      ], */
      thermostatData: [
        { value: null, text: "Thermostat", disabled: true },
        { value: "Digital", text: "Digital" },
        { value: "Manuel", text: "Manuel" }
      ],
      stockTypesData: [
        {
          value: null,
          text: this.$t("titles.stockTypes.text"),
          disabled: true,
        },
        { value: "24 H", text: "24 H" },
        { value: "VIDE", text: "VIDE" },
      ],
      vaccintypesFilterData: [{ value: null, text: "--All--" }],
      vaccintypesSelect: [null],
      vaccintypesData: [],
      region: null,
      firstRegion: true,
      deviceEdit: {
        deviceId: null,
        serial: null,
        ref: null,
        marque: null,
        thermostat: null,
        name: null,
        lat: null,
        lng: null,
        type: null,
        region: null,
        province: null,
        stockType: null,
        vaccinTypes: null,
        phones: null,
      },
      err: {
        serial: null,
        ref: null,
        name: null,
        marque: null,
        thermostat: null,
        lat: null,
        lng: null,
        type: null,
        region: null,
        province: null,
        stockType: null,
        vaccinTypes: null,
      },
    };
  },
  watch: {
    device(newVal) {
      /* Object.assign(this.deviceEdit, {region: newVal, province: null});
      this.retrieveProvinces(newVal.region.id) */
      this.vaccintypesSelect = [null],
      this.firstRegion = true;
      this.region = newVal.region.id;

      /* if(newVal.stockType == "VIDE") this.chargeVaccintypes(newVal.vaccintypes.filter(obj => obj.name == "BackUp"));
      else this.chargeVaccintypes(newVal.vaccintypes.filter(obj => obj.name != "BackUp")); */

      let originvaccins = newVal.vaccinTypes.map((obj) => {
        this.vaccintypesSelect.push(obj.id);
        return obj.id;
      });

      this.deviceEdit = {
        deviceId: newVal.id,
        serial: newVal.serial,
        ref: newVal.ref,
        marque: newVal.marque.id,
        thermostat: newVal.thermostat ? newVal.thermostat : null,
        name: newVal.name,
        lat: newVal.lat,
        lng: newVal.lng,
        type: newVal.type,
        region: newVal.region.id,
        province: newVal.province.id,
        stockType: newVal.stockType ? newVal.stockType : null,
        phones: newVal.phones,
        vaccinTypes: originvaccins,
      };
      
    },
    regions(newVal) {
      let regionsList = newVal;
      regionsList.map((obj) => {
        this.regionsData.push({ value: obj.id, text: obj.name });
      });
    },
    marques(newVal) {
      let marquesList = newVal;
      marquesList.map((obj) => {
          this.marquesData.push({ value: obj.id, text: obj.name });
      });
    },
    region(newVal) {
      Object.assign(this.deviceEdit, { region: newVal });
      this.retrieveProvinces(newVal, this.firstRegion);
      this.firstRegion = false;
    },
    vaccintypesSelect(newVal) {
      let lenVaccintypes = newVal.length;

      if (!newVal[lenVaccintypes - 1] && lenVaccintypes > 1) {
        this.vaccintypesSelect = [null];
        this.deviceEdit.vaccinTypes = null;
      } else if (newVal[lenVaccintypes - 1] && newVal.includes(null)) {
        var filteredVaccintypes = this.vaccintypesSelect.filter(function (
          item
        ) {
          return item != null;
        });
        this.vaccintypesSelect = filteredVaccintypes;
      } else if (newVal[lenVaccintypes - 1] && !newVal.includes(null)) {
        this.deviceEdit.vaccinTypes = newVal;
      } else if (newVal.length == 0) {
        this.vaccintypesSelect = [null];
        this.deviceEdit.vaccinTypes = null;
      }
    },
    'deviceEdit.stockType'(newVal){
      this.vaccintypesSelect = [null];
      this.vaccintypesFilterData = [{ value: null, text: "--All--" }]
      if(newVal == "VIDE") this.chargeVaccintypes(this.vaccintypes.filter(obj => obj.name == "BackUp"));
      else this.chargeVaccintypes(this.vaccintypes.filter(obj => obj.name != "BackUp"));
    },
    vaccintypes(newVal){
      this.vaccintypesFilterData = [{ value: null, text: "--All--" }]
      if(this.device.stockType == "VIDE") this.chargeVaccintypes(newVal.filter(obj => obj.name == "BackUp"));
      else this.chargeVaccintypes(newVal.filter(obj => obj.name != "BackUp"));
    },

    /* provinces(newVal) {
      let provincesList = newVal;
      provincesList.map((obj) => {
          this.provincesData.push({ value: obj.id, text: obj.name });
      });
    }, */
  },
  methods: {
    retrieveProvinces(region) {
      if (!this.firstRegion) Object.assign(this.deviceEdit, { province: null });
      this.provincesData = [
        { value: null, text: this.$t("titles.provinces.text"), disabled: true },
      ];
      let provinces = this.provinces;
      provinces.map((obj) => {
        if (obj.region == region)
          this.provincesData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeVaccintypes(vaccintypes) {
      let vData = vaccintypes || [];
      vData.map((obj) => {
        if(obj.name != "Covid (1/2)") this.vaccintypesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    cancel() {
      this.clear();
    },
    clear() {
      this.err = {
        serial: null,
        ref: null,
        marque: null,
        thermostat: null,
        name: null,
        lat: null,
        lng: null,
        type: null,
        region: null,
        province: null,
        stockType: null,
      };
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    edit(ok) {
      const serialReg = /^\d+$/;
      if (!this.deviceEdit.serial || !serialReg.test(this.deviceEdit.serial))
        this.err.serial = this.$t("msgError.obligField.text");
      else this.err.serial = "";

      if (!this.deviceEdit.name)
        this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.deviceEdit.ref)
        this.err.ref = this.$t("msgError.obligField.text");
      else this.err.ref = "";

      if (!this.deviceEdit.type)
        this.err.type = this.$t("msgError.obligField.text");
      else this.err.type = "";

      if (!this.deviceEdit.region)
        this.err.region = this.$t("msgError.obligField.text");
      else this.err.region = "";

      if (!this.deviceEdit.province)
        this.err.province = this.$t("msgError.obligField.text");
      else this.err.province = "";

      if (!this.deviceEdit.stockType)
        this.err.stockType = this.$t("msgError.obligField.text");
      else this.err.stockType = "";

      if (!this.deviceEdit.marque)
        this.err.marque = this.$t("msgError.obligField.text");
      else this.err.marque = "";

      if (
        !this.err.serial &&
        !this.err.type &&
        !this.err.name &&
        !this.err.ref &&
        !this.err.region &&
        !this.err.province &&
        !this.err.stockType &&
        !this.err.marque &&
        !this.err.thermostat
      ) {
        if (
          this.deviceEdit.serial != this.device.serial ||
          this.deviceEdit.type != this.device.type ||
          this.deviceEdit.name != this.device.name ||
          this.deviceEdit.ref != this.device.ref ||
          this.deviceEdit.marque != this.device.marque ||
          this.deviceEdit.thermostat != this.device.thermostat ||
          this.deviceEdit.region != this.device.region.id ||
          this.deviceEdit.province != this.device.province.id ||
          this.deviceEdit.ref != this.device.ref ||
          this.deviceEdit.stockType != this.device.stockType ||
          this.deviceEdit.vaccinTypes != this.device.vaccinTypes ||
          this.deviceEdit.lat != this.device.lat ||
          this.deviceEdit.lng != this.device.lng ||
          this.deviceEdit.phones != this.device.phones
        ) {
          /* let deviceData = {
            serial: this.deviceEdit.serial ? this.deviceEdit.serial : null,
            ref: this.deviceEdit.ref ? this.deviceEdit.ref : null,
            name: this.deviceEdit.name ? this.deviceEdit.name : null,
            type: this.deviceEdit.type ? this.deviceEdit.type : null,
            region: this.deviceEdit.region ? this.deviceEdit.region : null,
            province: this.deviceEdit.province ? this.deviceEdit.province : null,
            stockType: this.deviceEdit.stockType
              ? this.deviceEdit.stockType
              : null,
          }; */
          let filtredDevice = this.cleanObject(this.deviceEdit);
          this.$emit("editDevice", filtredDevice);
          ok();
        } else {
          ok();
        }
      }
    },
  },
};
</script>